import { BREAKPOINTS } from 'themes/dk/breakpoints'
import { NextArrow, PrevArrow } from 'features/GamesSlider/styled'
import React from 'react'
/*Providers Media*/
import playngoLogo from 'images/providers/playngo-mono.svg'
import playngoPerson from 'images/providers/person/playngo.png'
import playngoBgImage from 'images/providers/banners/playngo.png'
import playngoVert from 'images/providers/vertical/PlaynGO.png'

import iSoftBetLogo from 'images/providers/isoftbet-mono.svg'
import iSoftBetPerson from 'images/providers/person/isoftbet.png'
import iSoftBgImage from 'images/providers/banners/isoftbet.png'
import iSoftVert from 'images/providers/vertical/iSoftbet.png'

import thunderkickLogo from 'images/providers/thunderkick.svg'
import thunderkickPerson from 'images/providers/person/thunderkick.png'
import thunderkickImage from 'images/providers/banners/thunderkick.jpg'
import thunderkickVert from 'images/providers/vertical/Thunderkick.png'

import yggdrasilLogo from 'images/providers/yggdrasil-mono.svg'
import yggdrasilPerson from 'images/providers/person/yggdrasil.png'
import yggdrasilBgImage from 'images/providers/banners/yggdrasil.png'
import yggdrasilVert from 'images/providers/vertical/Yggdrasil.png'

import netentLogo from 'images/providers/netent-mono.svg'
import netentPerson from 'images/providers/person/netent.png'
import netentBgImage from 'images/providers/banners/netent.png'
import netentVert from 'images/providers/vertical/Netent.png'

import playsonLogo from 'images/providers/playson-mono.svg'
import playsonPerson from 'images/providers/person/playson.png'
import playsonBgImage from 'images/providers/banners/playson.png'
import playsonVert from 'images/providers/vertical/Playson.png'

import evolutionLogo from 'images/providers/evolution-mono.svg'
import evolutionPerson from 'images/providers/person/evolution.png'
import evolutionBgImage from 'images/providers/banners/evolution.png'
import evolutionVert from 'images/providers/vertical/Evolution.png'

import authenticLogo from 'images/providers/authentic-mono.svg'
import authenticPerson from 'images/providers/person/authentic.png'
import authenticBgImage from 'images/providers/banners/authentic.jpg'
import authenticVert from 'images/providers/vertical/Authentic-gaming.png'

import elkLogo from 'images/providers/elk-mono.svg'
import elkPerson from 'images/providers/person/elk.png'
import elkBgImage from 'images/providers/banners/elk.png'
import elkVert from 'images/providers/vertical/ELK.png'

import quickspinLogo from 'images/providers/quickspin-mono.svg'
import quickspinPerson from 'images/providers/person/quickspin.png'
import quickspinBgImage from 'images/providers/banners/quickspin.jpg'
import quickspinVert from 'images/providers/vertical/Quickspin.png'

import wazdanLogo from 'images/providers/wazdan-mono.svg'
import wazdanPerson from 'images/providers/person/wazdan.png'
import wazdanBgImage from 'images/providers/banners/wazdan.jpg'
import wazdanVert from 'images/providers/vertical/Wazdan.png'

import scientificLogo from 'images/providers/scientific-mono.svg'
import scientificBgImage from 'images/providers/banners/scientific.png'
import scientificPerson from 'images/providers/person/scientific.png'
import scientificVert from 'images/providers/vertical/Scientific-gaming.png'

import btgLogo from 'images/providers/btg-mono.svg'
import btgBgImage from 'images/providers/banners/btg.png'
import btgPerson from 'images/providers/person/btg.png'
import btgVert from 'images/providers/vertical/Big-time-gaming.png'

import gamevyLogo from 'images/providers/gamevy-mono.svg'
import gamevyBgImage from 'images/providers/banners/gamevy.png'
import gamevyPerson from 'images/providers/person/gamevy.png'
import gamevyVert from 'images/providers/vertical/Gamevy.png'

import lightboxLogo from 'images/providers/lightbox-mono.svg'
import lightboxBgImage from 'images/providers/banners/lightbox.png'
import lightboxPerson from 'images/providers/person/lightbox.png'
import lightboxVert from 'images/providers/vertical/Lightning-box.png'

import hacksawLogo from 'images/providers/hacksaw-mono.svg'
import hacksawBgImage from 'images/providers/banners/hacksaw.png'
import hacksawPerson from 'images/providers/person/hacksaw.png'
import hacksawVert from 'images/providers/vertical/Hacksaw.png'

import nolimitLogo from 'images/providers/nolimit-mono.svg'
import nolimitBgImage from 'images/providers/banners/nolimit.jpg'
import nolimitPerson from 'images/providers/person/nolimit.png'
import nolimitVert from 'images/providers/vertical/Nolimit-city.png'

import redtigerLogo from 'images/providers/redtiger-mono.svg'
import redtigerBgImage from 'images/providers/banners/redtiger.jpg'
import redtigerPerson from 'images/providers/person/redtiger.png'
import redtigerVert from 'images/providers/vertical/Red-Tiger.png'

import oneXtwoLogo from 'images/providers/1X2-mono.svg'
import oneXtwoBgImage from 'images/providers/banners/1x2.jpg'
import oneXtwoPerson from 'images/providers/person/1x2.png'
import oneXtwoVert from 'images/providers/vertical/1x2-gaming.png'

import blueprintLogo from 'images/providers/blueprint-mono.svg'
import blueprintBgImage from 'images/providers/banners/blueprint.jpg'
import blueprintPerson from 'images/providers/person/blueprint.png'
import blueprintVert from 'images/providers/vertical/Blueprint.jpg'

import pragmaticLogo from 'images/providers/pragmatic-mono.svg'
import pragmaticBgImage from 'images/providers/banners/pragmatic.jpg'
import pragmaticPerson from 'images/providers/person/pragmatic.png'
import pragmaticVert from 'images/providers/vertical/Pragmatic.jpg'

import relaxLogo from 'images/providers/relax-mono.svg'
import relaxBgImage from 'images/providers/banners/relax.jpg'
import relaxPerson from 'images/providers/person/relax.png'
import relaxVert from 'images/providers/vertical/Relax.jpg'

import stakelogicLogo from 'images/providers/stakelogic-mono.svg'
import stakelogicBgImage from 'images/providers/banners/stakelogic.jpg'
import stakelogicPerson from 'images/providers/person/stakelogic.png'
import stakelogicVert from 'images/providers/vertical/Stakelogic.jpg'

/*HEADER*/
export const TOPBAR_DESKTOP_HEIGHT = '40px'
export const TOPBAR_MOBILE_HEIGHT = '20px'
export const HEADER_DESKTOP_HEIGHT = '84px'
export const HEADER_MOBILE_HEIGHT = '60px'
export const FULL_HEADER_SIZE_DESKTOP = 124
export const FULL_HEADER_SIZE_MOBILE = 80

/*BOTTOM SECTION*/
export const BOTTOM_NAVIGATION_HEIGHT = '60px'
export const BOTTOM_LOGIN_HEIGHT = '66px'
export const FULL_BOTTOM_HEIGHT = '126px'

/*COMMON SLIDER SETTINGS*/

export const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: BREAKPOINTS.lg,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2,
            },
        },
        {
            breakpoint: BREAKPOINTS.sm,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1,
            },
        },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
}

export const ProvidersBanner = {
    stakelogic: {
        image: stakelogicPerson,
        logo: stakelogicLogo,
        bgImage: stakelogicBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    relax: {
        image: relaxPerson,
        logo: relaxLogo,
        bgImage: relaxBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    pragmatic: {
        image: pragmaticPerson,
        logo: pragmaticLogo,
        bgImage: pragmaticBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    blueprint: {
        image: blueprintPerson,
        logo: blueprintLogo,
        bgImage: blueprintBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    '1x2': {
        image: oneXtwoPerson,
        logo: oneXtwoLogo,
        bgImage: oneXtwoBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    redtiger: {
        image: redtigerPerson,
        logo: redtigerLogo,
        bgImage: redtigerBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    isoftbet: {
        image: iSoftBetPerson,
        logo: iSoftBetLogo,
        bgImage: iSoftBgImage,
        styles: {
            bgPosition: 'center -50px',
        },
    },
    thunderkick: {
        image: thunderkickPerson,
        logo: thunderkickLogo,
        bgImage: thunderkickImage,
        styles: {
            bgPosition: 'center -50px',
            logoSize: '15%',
        },
    },
    playngo: {
        image: playngoPerson,
        logo: playngoLogo,
        bgImage: playngoBgImage,
        styles: {
            bgPosition: 'center 0',
        },
    },
    nolimit: {
        image: nolimitPerson,
        logo: nolimitLogo,
        bgImage: nolimitBgImage,
        styles: {
            bgPosition: 'center 0',
        },
    },
    yggdrasil: {
        image: yggdrasilPerson,
        logo: yggdrasilLogo,
        bgImage: yggdrasilBgImage,
    },
    netent: {
        image: netentPerson,
        logo: netentLogo,
        bgImage: netentBgImage,
        styles: {
            logoSize: '15%',
            bgPosition: 'center 0',
        },
    },
    playson: {
        image: playsonPerson,
        logo: playsonLogo,
        bgImage: playsonBgImage,
    },
    evolution: {
        image: evolutionPerson,
        logo: evolutionLogo,
        bgImage: evolutionBgImage,
    },
    authentic: {
        image: authenticPerson,
        logo: authenticLogo,
        bgImage: authenticBgImage,
        styles: {
            logoSize: '25%',
        },
    },
    elk: {
        image: elkPerson,
        logo: elkLogo,
        bgImage: elkBgImage,
        styles: {
            logoSize: '11%',
        },
    },
    hacksaw: {
        image: hacksawPerson,
        logo: hacksawLogo,
        bgImage: hacksawBgImage,
        styles: {
            logoSize: '11%',
        },
    },
    quickspin: {
        image: quickspinPerson,
        logo: quickspinLogo,
        bgImage: quickspinBgImage,
    },
    wazdan: {
        image: wazdanPerson,
        logo: wazdanLogo,
        bgImage: wazdanBgImage,
    },
    scientific: {
        image: scientificPerson,
        logo: scientificLogo,
        bgImage: scientificBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    btg: {
        image: btgPerson,
        logo: btgLogo,
        bgImage: btgBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    gamevy: {
        image: gamevyPerson,
        logo: gamevyLogo,
        bgImage: gamevyBgImage,
        styles: {
            logoSize: '20%',
        },
    },
    lightbox: {
        image: lightboxPerson,
        logo: lightboxLogo,
        bgImage: lightboxBgImage,
        styles: {
            logoSize: '20%',
        },
    },
}

export const providersData = [
    {
        name: 'stakelogic',
        logo: stakelogicLogo,
        verticalImage: stakelogicVert,
        width: '60%',
    },
    {
        name: 'relax',
        logo: relaxLogo,
        verticalImage: relaxVert,
        width: '60%',
    },
    {
        name: 'pragmatic',
        logo: pragmaticLogo,
        verticalImage: pragmaticVert,
        width: '100%',
        height: '60px',
    },
    {
        name: 'blueprint',
        logo: blueprintLogo,
        verticalImage: blueprintVert,
        width: '80%',
    },
    {
        name: '1x2',
        logo: oneXtwoLogo,
        verticalImage: oneXtwoVert,
        width: '60%',
    },
    {
        name: 'redtiger',
        logo: redtigerLogo,
        verticalImage: redtigerVert,
        width: '65%',
    },
    {
        name: 'scientific',
        logo: scientificLogo,
        verticalImage: scientificVert,
    },
    {
        name: 'btg',
        logo: btgLogo,
        verticalImage: btgVert,
        width: '80%',
    },
    {
        name: 'gamevy',
        logo: gamevyLogo,
        verticalImage: gamevyVert,
        width: '55%',
    },
    {
        name: 'lightbox',
        logo: lightboxLogo,
        verticalImage: lightboxVert,
    },
    {
        name: 'netent',
        logo: netentLogo,
        verticalImage: netentVert,
    },
    {
        name: 'yggdrasil',
        logo: yggdrasilLogo,
        verticalImage: yggdrasilVert,
        width: '70%',
    },
    {
        name: 'playngo',
        logo: playngoLogo,
        verticalImage: playngoVert,
        width: '65%',
    },
    {
        name: 'nolimit',
        logo: nolimitLogo,
        verticalImage: nolimitVert,
        width: '65%',
    },
    {
        name: 'evolution',
        logo: evolutionLogo,
        verticalImage: evolutionVert,
        width: '60%',
        isLive: true,
    },
    {
        name: 'authentic',
        logo: authenticLogo,
        verticalImage: authenticVert,
        width: '70%',
        isLive: true,
    },
    {
        name: 'isoftbet',
        logo: iSoftBetLogo,
        verticalImage: iSoftVert,
        width: '60%',
    },
    {
        name: 'thunderkick',
        logo: thunderkickLogo,
        verticalImage: thunderkickVert,
        width: '75%',
    },
    {
        name: 'playson',
        logo: playsonLogo,
        verticalImage: playsonVert,
        width: '50%',
    },
    {
        name: 'elk',
        logo: elkLogo,
        verticalImage: elkVert,
        width: '45%',
    },
    {
        name: 'hacksaw',
        logo: hacksawLogo,
        verticalImage: hacksawVert,
        width: '80%',
    },
    {
        name: 'wazdan',
        logo: wazdanLogo,
        verticalImage: wazdanVert,
        width: '100%',
    },
    {
        name: 'quickspin',
        logo: quickspinLogo,
        verticalImage: quickspinVert,
    },
]

export const EXCLUSIVE_GAME_LABEL = {
    background: '#97D800',
    textColor: '#fff',
    text: 'EKSKLUSIVT',
}

import React, { useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import styled from 'styled-components'
import ValidInput from 'ui/controls/ValidInput'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'services/UserService'
import withValidation from 'features/Hocs/WithValidation'
import { ModalService } from 'services/ModalService'
import getTexts from 'utils/localization'
import { COLORS } from 'themes'
import BgWrapper from 'ui/BgWrapper'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = getTexts()

const StyledInput = styled(Flex)`
    margin: 0;
`

const Voucher = ({ setValidationHandler, validateAllFields, close }) => {
    const [updateButtonDisable, setUpdateButtonDisable] = useState(false)

    const [code, setCode] = useState('')
    const [number, setNumber] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()

        if (validateAllFields()) {
            UserAdapter.applyVoucher(number, code)
                .then((res) => {
                    if (res) {
                        UserService.setIsUserLoggedIn()
                        ModalService.closeModal('VOUCHER')
                        NotificationConductor.success(
                            `Voucher godkendt! ${res} kr. er tilføjet til din konto.`
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                    NotificationConductor.error(err)
                })
        } else {
            NotificationConductor.error(t.filledWrong)
        }
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            submitHandler(e)
        }
    }

    return (
        <BgWrapper solid height={'100%'}>
            <ModalTopBar title="Voucher" modalName="VOUCHER" />
            <Wrapper
                onKeyDown={(e) => {
                    onKeyPress(e)
                }}
            >
                <Text
                    size={'x3'}
                    padding={'0 15px'}
                    bold
                    color={COLORS.primaryText}
                >
                    Indtast og modtag
                </Text>
                <Text
                    size={'x1'}
                    padding={'15px 15px 30px'}
                    color={COLORS.lightGrey}
                >
                    Har du modtaget en voucher, kan du indløse den herunder. Du
                    kan se regler og vilkår på din voucher.
                </Text>

                <StyledInput>
                    <ValidInput
                        type={detectMobile() ? 'tel' : 'text'}
                        onChange={(v) => {
                            setNumber(v.replace(/[^\d.]/gi, ''))
                        }}
                        value={number}
                        validate={{
                            required: true,
                            numeric: true,
                        }}
                        placeholder={t.userArea.voucher.number}
                        getValidator={(validatorHandler) =>
                            setValidationHandler('number', validatorHandler)
                        }
                        hideCancel
                        onKeyPress={onKeyPress}
                    />
                </StyledInput>
                <StyledInput>
                    <ValidInput
                        type={'text'}
                        onChange={(v) => {
                            setCode(v.trim())
                        }}
                        value={code}
                        placeholder={t.userArea.voucher.code}
                        validate={{
                            required: true,
                        }}
                        getValidator={(validatorHandler) => {
                            setValidationHandler('code', validatorHandler)
                        }}
                        hideCancel
                        onKeyPress={onKeyPress}
                    />
                </StyledInput>

                <Flex justify={'space-between'} margin="20px 0 0">
                    <Button
                        accent
                        size={'lgB'}
                        width={'100%'}
                        onClick={submitHandler}
                        disabled={!code || !number}
                    >
                        {t.userArea.voucher.btn}
                    </Button>
                </Flex>
            </Wrapper>
        </BgWrapper>
    )
}

export default withValidation(Voucher)

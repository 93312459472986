import React, { Fragment } from 'react'
import Wrapper from 'ui/Wrapper'
import getTexts from 'utils/localization'
import Text from 'ui/Text'
import { Headline4 } from 'ui/Headline'
import moment from 'moment'

const t = getTexts()

const Limits = (props) => {
    const LIMITS = props.limits

    return (
        <Wrapper margin="50px 0 10px 0">
            <Text padding="0" block align="center" grey>
                {`${t.userArea.depositLimit.current} ${
                    props.curTab === 'daily'
                        ? t.userArea.depositLimit.daily
                        : props.curTab === 'weekly'
                            ? t.userArea.depositLimit.weekly
                            : t.userArea.depositLimit.monthly
                } 
                    ${t.userArea.depositLimit.limit}`}
            </Text>
            <Headline4 padding="0" block align="center">
                {LIMITS.current} {t.currency}
            </Headline4>
            {LIMITS.newLimit ? (
                <Fragment>
                    <Text padding="15px 0 0" block align="center" grey>
                        {`${t.userArea.depositLimit.next} ${
                            props.curTab === 'daily'
                                ? t.userArea.depositLimit.daily
                                : props.curTab === 'weekly'
                                    ? t.userArea.depositLimit.weekly
                                    : t.userArea.depositLimit.monthly
                        } 
                    ${t.userArea.depositLimit.limit}`}
                    </Text>
                    <Headline4 padding="0" block align="center">
                        {LIMITS.newLimit} {t.currency}
                    </Headline4>
                    <Text padding="0" red block align="center">
                        {`
                        ${t.userArea.depositLimit.bottom} 
                        ${
                            LIMITS.date
                                ? moment(
                                      LIMITS.date,
                                      'DD.MM.YYYY HH:mm'
                                  ).format('DD MMM YYYY HH:mm')
                                : '-'
                        }
                        `}
                    </Text>
                </Fragment>
            ) : null}
        </Wrapper>
    )
}

export default Limits

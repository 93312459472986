import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import NewSignUp from '@it25syv/sign-up'
import { ModalService } from 'services/ModalService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { BREAKPOINTS } from 'themes/dk/breakpoints'
import { detectIOS } from 'common/adapters/DeviceDetectAdapter'

const SignUpWrapper = styled.section`
    height: 100vh;
    overflow: auto;
    padding: 15px;
    box-sizing: border-box;
    scrollbar-width: none;
    ${(props) =>
        props.detectIOS &&
        css`
            @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
                height: 100%;
                height: fill-available;
                height: stretch;
            }
        `};

    &::-webkit-scrollbar {
        display: none;
    }
`

const SignUp = () => {
    const scrollRef = useRef(null)

    return (
        <SignUpWrapper ref={scrollRef} detectIOS={detectIOS()}>
            <NewSignUp
                defaultStep={1}
                onEmailExists={() => {
                    ModalService.showModal('EMAIL_EXIST', {
                        initialProps: { modalTopMargin: '0' },
                        message: 'email',
                    })
                }}
                onCPRExists={() => {
                    ModalService.showModal('EMAIL_EXIST', {
                        initialProps: { modalTopMargin: '0' },
                        message: 'cpr',
                    })
                }}
                showError={(error) => NotificationConductor.error(error)}
                onClose={() => {
                    window.history.pushState('', '', '/')
                    ModalService.closeModal()
                }}
                scrollableWrapperRef={scrollRef}
                theme="dark"
                showBonusCode={false}
                title="Opret konto"
                project="casinogo" //check where it's used
                avatar={{
                    path: '/avatars/avatar',
                    show: true,
                }}
                step1Headline={'Modtag nyheder, tilbud mv.'}
            />
        </SignUpWrapper>
    )
}

export default SignUp

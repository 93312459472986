import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Input from 'ui/controls/Input'
import Text from 'ui/Text'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import getTexts from 'utils/localization'
import { thousandsDots, ValidatingInput } from '@it25syv/25syv-ui'
const t = getTexts()

const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const EditSection = ({
    curLimit,
    limits,
    setCurLimit,
    setEditMode,
    setAllLimits,
}) => {
    const [depositRef, setDepositRef] = useState(null)
    const [errors, setErrors] = useState({
        depositLimit: '',
    })

    const onCancel = () => {
        setCurLimit('')
        setEditMode(false)
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            setAllLimits()
        }
    }

    const checkValue = (error) => {
        if (error) {
            setErrors({ ...errors, [error.name]: error.value })
        }
    }

    const isDataValid = curLimit && !errors.depositLimit

    const formattedBottomLabel = useCallback(
        (maxValue) => (
            <Fragment>
                Max. grænse er <b>{thousandsDots(maxValue)}</b> kr.{' '}
                <span style={{ textTransform: 'lowercase' }}>
                    {limits.label}
                </span>
            </Fragment>
        ),
        [limits]
    )

    return (
        <Fragment>
            <ValidatingInput
                format={String(limits.max).replace(/\d/g, '#')}
                type="number"
                name="depositLimit"
                onCheckValue={checkValue}
                validate="required, numeric, minimum-kr, maximum-kr"
                onChangeHandler={(value) => {
                    setCurLimit(value)
                }}
                altLabel="kr."
                allowFirstZero={false}
                maxNumber={limits.max}
                minNumber={50}
                value={curLimit}
                bottomLabel={formattedBottomLabel(limits.max)}
                styles={{ InputWrapper: { marginTop: '14px' } }}
                forwardedRef={(ref) => setDepositRef(ref)}
                autoComplete="new-depositLimit"
                role="presentation"
                shouldRevalidateOnBlur={(relatedTarget) => {
                    return relatedTarget
                        ? !relatedTarget.hasAttribute('data-deposit-max-amount')
                        : true
                }}
                validateOnChange
                onKeyPress={(e) => curLimit && onKeyPress(e)}
                placeholder={t.userArea.depositLimit.inputPlaceholder}
                theme={'dark'}
            />
            {curLimit ? (
                <Text
                    margin="-20px auto 10px"
                    padding="0 15px"
                    size="x07"
                    lightGrey
                >
                    {t.userArea.depositLimit.inputBottomText}
                </Text>
            ) : null}
            <ButtonsRow>
                <Button
                    width="45%"
                    accent
                    onClick={setAllLimits}
                    disabled={!isDataValid}
                >
                    {t.userArea.depositLimit.setLimit}
                </Button>
                <Button width="45%" black onClick={onCancel}>
                    {t.userArea.depositLimit.cancel}
                </Button>
            </ButtonsRow>
        </Fragment>
    )
}
export default EditSection
